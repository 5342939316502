import http from "@/utils/request";
export function messageFilterList(data) {
    return http({
      method: "get",
      url: "/priceMessage/messageList.nd",
      data
    });
  }

  // billboard/loginMessagenoticeListforplate.htm?pageNo=1&plate=%E4%BA%A7%E5%93%81%E5%BF%AB%E8%AE%AF&noticeText=&dateStart=&dateEnd=
  export function announcementList(data) {
    return http({
      method: "get",
      url: "/billboard/loginMessagenoticeListforplate.htm",
      params: data
    });
  }
  // 查询搜索条件  http://b2b-front-cis.devapps.hisense.com/billboard//loginMessagenoticeList.htm
  export function swiperData(data) {
    return http({
      method: "get",
      url: "/billboard/loginMessagenoticeList.htm",
      params: data
    });
  }