<!--
 * @Author: mayu
 * @Date: 2020-10-30 16:53:13
 * @LastEditTime: 2020-10-30 18:51:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\announcement\index.vue
-->



<template>
  <div class="shop-history swiper-box"  >
    <div class="swiper-container" ref="mySwiper" >
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,index) in data" :key="index">
          <div
            class="card-ani-box animated fadeInRight"
            
          >
            <div class="imgContent">
               <!-- <img :src="item.src" /> -->
                <!-- <img :src="item.img" alt /> -->
                <img @click="gotodetail(item.code)"  v-if="item.img" :src="'http://nginx-proxy-cis.devapps.hisense.com/notice/'+item.img" alt="">
                
                <!-- <img @click="gotodetail(item.code)" v-else src="./../../../../assets/anncunment/img3.png" alt=""> -->
                
            </div>
            <div class="rightContent">
              <div class="rightTopext">
                <div>
                  
                    <p class="title" @click="gotodetail(item.code)">{{item ? item.title:''}} </p>
                    <span v-if="item.filePath.length>0" class="iconbox" style="color:#1890FF;font-size:14px;" @click="downFile(item)">  附件
                      <i @click="downFile(item)" class="iconfont icon-fujian" style="color:#1890FF;font-size:14px"></i>
                    </span>
                </div>
                
                
                <p class="smallContent">{{item?item.content:''}}</p>
              </div>
              <div class="rightBottomText">
                <p class="time">
                  {{item? item.organizationName :''}}
                  {{item? item.createDateStr : ''}} 
                  {{item.name?item.name:''}}</p>
                <!-- <div class="importFlag">重要</div> -->
                <div class="importantBox" v-if="item.priority==1">
                  <div  class="important">重要</div>
                </div>
              </div>
             
            </div>
          </div>
          
        </div>
      </div>
      <!-- 如果需要分页器:style="'border-top:1px solid' + borderColorFun(index)" -->
      <div class="swiper-pagination" ref="pagination"></div>
      <!--箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <!-- <div class="swiper-button-prev animated fadeInRight"></div>
      <div class="swiper-button-next animated fadeInLeft"></div> -->
    </div>
  </div>
</template>

<script>

export default {
  name: "announceList",
  data() {
    return {};
  },
  
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'data': function() {
       this.$nextTick(() => {
        this.initSwiper()
       });
    }
    
  },
  

  mounted() {
 
    this.$nextTick(() => {
      this.initSwiper()
    });
  },
  methods: {
    gotodetail(id) {
      this.$router.push({
        name: "announcementDetail",
        params: {
          id: id,
        },
      });
    },
    downFile(item){
    },
    initSwiper () {
      let this_ = this;
      var mySwiper = new this.$Swiper(this.$refs.mySwiper, {
        on: {
          slideChange: function() {
          },
          click: () => {

          }
        },
        autoplay: {
          //自动轮播
          delay: 5000
          //   disableOnInteraction: false
          // autoplayDisableOnInteraction : false,
          // stopOnLastSlide:true
        },
        loop: true, // 循环模式选项
        // direction: "vertical",
        onlyExternal: true,
        // noSwiping : true,
        // simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
        // slidesPerView: 4,
        // slidesPerGroup: 4,
        // spaceBetween: 12,
        // preventLinksPropagation:false,//防止冒泡。
        // observer: true, //修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //         // 如果需要分页器
        pagination: {
          el: this_.$refs.pagination,
          //   paginationClickable: true,
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
      mySwiper.el.onmouseover = function() {
        //鼠标放上暂停轮播
        mySwiper.autoplay.stop();
      };
      mySwiper.el.onmouseleave = function() {
        mySwiper.autoplay.start();
      };
    }
  }
};
</script>
<style lang="less" >
.shop-history.swiper-box {
  width: 1188px!important;
  // height: 394px!important;
  margin: 0 auto;

  .swiper-container {
    // margin-left: 12px;
    width: 1188px!important;
    height: 360px!important;
    float: left;
    // padding: 12px 12px 28px;
     padding: 0px 0px 34px 0px;
     box-sizing: content-box;
    // margin-left: -12px;
    .swiper-slide {
      width: 1188px!important;
      height: 360px!important;
      background: #ffffff;
   
      .card-ani-box {
        display: flex;
        justify-content: space-between;
        .imgContent {
          margin-top:16px;
          margin-left: 16px;
          // background: rgb(208, 235, 182);
          width: 604px;
          height: 328px;
          overflow: hidden;
          img {
              z-index:10;
              width: 604px!important;
              height: 328px!important;
              cursor: pointer;
              transition: all .5s;
          }
          &:hover{
            img{
              transform: scale(1.06);
            }
          }
        }
        .rightContent {
          border: 1px solid #fff;
          width: 536px;
          height: 328px;
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 16px;

          .rightTopext {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            div{
              display: flex;
               flex-direction: row;
              justify-content: flex-start;

            }
            .iconbox {
              width: 60px;
              margin-right: 10px;
              height: 32px;
              line-height: 32px;
            }
            .title {
              width: 480px;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              text-align: left;
              // margin-top: 24px;
              font-size: 20px;
              font-weight: 500;
              color: #262626;
              line-height: 31px;
              cursor: pointer;
            }
            .smallContent {
              width: 536px;
              margin-top: 14px;
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              line-height: 24px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5; //这儿的数字代表的就是你所需要实现效果的第N行
              -webkit-box-orient: vertical;
            }
          }
          .rightBottomText {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            margin-bottom: 24px;
            .time {
              // width: 191px;
              height: 16px;
              font-size: 14px;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 16px;
            }
            .importantBox{
                width: 38px;
                height: 20px;
                // margin: 0 auto;
                position: relative;
                /*给父元素设置渐变背景色*/
                // background: -webkit-linear-gradient(30deg, pink,rosybrown);
                background:linear-gradient(90deg,#54D6D2, #00AAA6);
                display: flex;
                align-items: center;
                justify-content: center;
                // border-image: linear-gradient(90deg, rgba(84, 214, 210, 1), rgba(0, 170, 166, 1)) 1 1;
              /*给父元素设置圆角边框*/
                border-radius: 10px;
                .important{
                  width: 36px;
                  height: 18px;
                  // position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  // margin: auto auto;
                  /*让子元素背景色同页面背景色一致*/
                  background: #fff;
                  /*让子元素圆角同父元素保持一致*/
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  color: #00AAA6;
              }
            }
            .importFlag {
              width: 36px;
              height: 18px;
              border: 1px solid rgba(84, 214, 210, 1);
              // border-image: linear-gradient(90deg, rgba(84, 214, 210, 1), rgba(0, 170, 166, 1)) 1 1;
              border-radius: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              color: #00AAA6;
              margin-right: 19px;
            }
          }
        }
      }
    }
    .swiper-pagination {
      // position: absolute;
      text-align: center;
      -webkit-transition: 300ms opacity;
      -o-transition: 300ms opacity;
      transition: 300ms opacity;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      z-index: 10;
      margin-top: 16px;
    }
  
  }

  
}
</style>

