import { mapState } from "vuex";
import { announcementList ,swiperData} from "./api";
import util from "./components/until";
import announceList from "./components/announceList";
import sortBar from "./components/SortBar";
import { infoImgUrl } from "@/common/constant.js";
export default {
  components: {
    sortBar,
    announceList,
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // let h = date.getHours();
      // h = h < 10 ? ('0' + h) : h;
      // let m = date.getMinutes();
      // m = m < 10 ? ('0' + m) : m;
      // let s = date.getSeconds();
      // s = s < 10 ? ('0' + s) : s;
      return y + "-" + MM + "-" + d;
    },
  },
  name: "index",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/announcement",
          name: "announcement",
          title: "公告",
        },
      ],
      sortList: [],
      swiperList: [],
      cardItemName: [],
      filterFrom: {
        pageNo: 1,
        status: "",
        type: "",
        noticeText: "",
        pageSize: 10,
        orderBy: "desc",
        plate: "",
      },
      isSearch: true,
      total: "", //搜索到几条数据
      // pending : '' ,//待处理
      totalPages: "", //数据总条数
      currentPage: "", //当前页数
      pageLoadFlag: false,
      infoImgUrl:'',
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
    }),
  },
  mounted() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode:this.filterConfigStr ? this.filterConfigStr: '' };
    this.sortList = util.sortList;
    // this.getcardItemName();
    this.getswiperData()

    this.infoImgUrl = infoImgUrl;

  },
  methods: {
    // 更改搜索框内容
    changeSearchVal(searchStr) {
      this.filterFrom = { ...this.filterFrom, content:searchStr, pageNo: 1 };
    },
    //获取商品列表
    async getcardItemName() {
      this.pageLoadFlag = true;
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: 1,
      };
      try {
        const res = await announcementList(this.filterFrom);
        // 渲染出来的数据列表
        this.cardItemName = res.data.list;
        // 查询出来的总数量
        this.total = res.data.datasCount;
        // 未读的数量
        // this.pending = res.data.unreadMessageNum
        // 重新赋值页数
        this.totalPages = res.data.totalPages;
        this.currentPage = res.data.currentPage;
        this.pageLoadFlag = false;
      } catch (error) {
        console.log(error);
      }
    },
    getswiperData() {  

      announcementList({}).then(res => {
        let swiperDataArr = [];
        if( res.data.list.length > 0 ) {
        res.data.list.forEach((item) => {
          if(item.headline == true) {
            swiperDataArr.push({
              code:item.id,
              id: item.id,
              name: item.classifyName,
              organizationName:item.organizationName,
              content:item.content,
              createDateStr:item.createDateStr,
              title:item.title,
              titleImg:item.titleImg,
              priority:item.priority,
              fileName:item.fileName,
              filePath:item.filePath,
              img:item.img
            });
          }
        });
        }
        this.swiperList = swiperDataArr;
      }).catch(error => {
        console.log(error)
      })
    },
    onFilterChange(checkedObj) {
      //合并入参请求对象
      this.filterFrom = { ...this.filterFrom, ...checkedObj[0], pageNo: 1 };

      this.cardItemName = [];

      //查询列表
      this.getcardItemName();

      this.isSearch = false;
    },
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.filterFrom = {
        ...this.filterFrom,
        orderBy: sortStatus == 0 ? "" : sortStatus == 1 ? "desc" : "asc",
      };
      this.getcardItemName();
    },
    // 搜索
    onSearch(noticeText) {
      this.cardItemName = [];
      this.filterFrom = { ...this.filterFrom, noticeText };
      this.getcardItemName();
      this.isSearch = false;
    },
    // 加载更多
    loadMore() {
      this.filterFrom = {
        ...this.filterFrom,
        pageNo: this.filterFrom.pageNo + 1,
      };
      this.pageLoadFlag = true;

      announcementList(this.filterFrom)
        .then((res) => {
          // 点击加载更多 拼接数组
          this.cardItemName = this.cardItemName.concat(res.data.list);
          this.pageLoadFlag = false;
          this.totalPages = res.data.totalPages;
          this.currentPage = res.data.currentPage;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 查看详情
    gotodetail(id) {
      this.$router.push({
        name: "announcementDetail",
        query: {
          id: id,
        },
      });
    },
    // 下载附件  https://3s-static.hisense.com/notice/202101/d69fe335-9c93-41f2-9b7d-fd55c110af05/%E5%BA%93%E5%AD%98%E4%B8%AD%E5%BF%83%E5%9F%B9%E8%AE%AD%E8%B5%84%E6%96%99-GIC%E6%93%8D%E4%BD%9C%E6%8C%87%E5%BC%95-20201130.pptx
    downFile(item) {
    
    }
  },
};
